
$secondary-font-family: 'Montserrat';

@import './inc/vars';
@import '~bootstrap/scss/bootstrap';
@import './inc/bootstrap-overrides';
@import './inc/modal';

@import './inc/bem';

html,
body {
  height: 100%;
}
