@import 'vars';

.modal {
  .modal-content {
    border-radius: $modal-border-radius;
    border: none;

    .modal-header {
      border: none;
      padding: 30px 40px 20px;

      .modal-title {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
      }

      .close {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: #E7E9F2;
        opacity: 1;
        padding: 0;
        font-weight: normal;
        color: #555;
        font-size: 18px;
      }
    }

    .modal-body {
      padding: 0 40px 60px;
      font-size: 13px;
    }

    .modal-footer {
      padding: 0;
      display: flex;
      border: none;

      > * {
        flex-grow: 1;
      }

      > .btn {
        border-radius: 0;
        border: none;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 600;
        margin: 0;

        & + .btn {
          margin-left: 0;
        }
        &.btn-secondary {
          color: #373451;
          background-color: #F5F6FA;
        }
        &.btn-primary {
          background-color: $color-modal-btn-primary;
        }
        &.btn-danger {
          background-color: $color-modal-btn-danger;
        }

        &:first-child {
          border-bottom-left-radius: $modal-border-radius;
        }
        &:last-child {
          border-bottom-right-radius: $modal-border-radius;
        }
      }
    }
  }
}

.modal-open .modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  background-color: $modal-backdrop-color;
  opacity: 0.5;
}
