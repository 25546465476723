.b-header {
    position: absolute;
    width: 100%;
    &__logo {
        margin-top: 26px;
        margin-left: 50px;
    }
    &__links {
        float: right;
        margin-right: 50px;
        margin-top: 21px;
        color: $text-muted;
        .btn {
            margin-left: 13px;
        }
    }
}

@include mobile {
    .b-header {
        &__logo {
            margin-top: 14px;
            margin-left: 18px;
            width: 116px;
        }
        &__links {
            margin-top: 13px;
            margin-right: 15px;
            &-message {
                display: none;
            }
        }
    }
}
