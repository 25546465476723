
@import 'mixins';

@import './b-central';
@import './b-header';
@import './b-verificode';

.b-all-height {
  height: 100%;
  > * {
    height: 100%;
  }
}

.b-logo-bg {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.b-email-input-group {
  min-height: 80px;
  > * {
    display: inline-block;
    vertical-align: top;
  }
  &__input {
    width: 300px;
  }
  .btn {
    margin-left: 13px;
  }
}

.b-role-box {
  border: 1px solid $gray-300;
  border-radius: 6px;
  height: 103px;
  display: block;
  color: $dark;
  padding-top: 21px;
  &.active,
  &:hover {
    text-decoration: none;
    color: $dark;
    border-color: $purple;
    box-shadow: 0 0 0 2px $purple;
    svg {
      fill: $purple;
      path {
        fill: $purple;
      }
    }
  }
  &__icon {
    margin-bottom: 15px;
  }
}

.b-btn-list {
  padding-inline-start: 0;
  &__item {
    border: 1px solid $input-border-color;
    border-radius: 4px;
    height: $input-height;
    line-height: $input-height - 1px;
    padding-left: 15px;
    list-style-type: none;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      border-color: $input-focus-border-color;
      box-shadow: 0 0 4px $input-focus-border-color;
    }
  }
  &__arrow-right {
    float: right;
    margin-top: 9px;
    margin-right: 10px;
  }
}

@include mobile {
  .b-email-input-group {
    > * {
      display: block;
    }
    &__input {
      width: 100%;
    }
    .btn {
      margin-left: 0;
      width: 100%;
    }
  }

  .b-role-boxes {
    .row {
      margin-right: -5px;
      margin-left: -5px;
    }
    .col-4 {
      padding: 5px;
    }
  }

  .b-role-box {
    padding-top: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    > svg {
      width: 20px;
      height: 20px;
    }
    &__text {
      margin-top: 5px;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
