.b-central {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    &--center {
      text-align: center;
      .form-group {
        text-align: left;
      }
    }

    max-height: calc(100vh - 68px - 68px);
    margin-top: 68px;
    margin-bottom: 68px;
    display: flex;
    flex-direction: column;
  }
  &__image {
    margin-bottom: 60px;
  }
  &__title {
    font-family: $secondary-font-family;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
    &--sm {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  &__subtitle {
    font-family: $secondary-font-family;
    margin-bottom: 45px;
  }

  &__panel {
    &--w400 {
      width: 400px;
    }
    &--w260 {
      width: 260px;
    }
    &--w600 {
      max-width: 600px;
    }
    &--center {
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }

    &--scrollable {
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ebebee;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
      }
    }
  }
  &__text-muted {
    color: $text-muted;
    font-size: 12px;
  }

  &__divider {
    border-top: 1px solid $gray-200;
    color: $text-muted;
    margin-top: 35px;
    margin-bottom: 24px;
    height: 0.8em;
    text-align: center;
    > * {
      position: relative;
      top: -0.8em;
      background-color: white;
      padding: 0 15px;
    }
  }

  &__footer {
    .btn {
      min-width: 150px;
      margin-left: 5px;
      margin-right: 5px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &--wide-btn {
      .btn {
        min-width: 250px;
        margin: 0;
      }
    }
    &--before-divider {
      margin-top: 50px;
      margin-bottom: 35px;
    }
  }

  &__corner {
    position: absolute;
    bottom: 0;
    left: 0;
    &--mobile {
      display: none;
    }
  }
}

@include mobile {
  .b-central {
    padding-top: 70px;
    padding-bottom: 20px;
    height: unset;
    min-height: 100%;
    &__content {
      padding: 0 30px;
      width: 100%;
    }
    &__message {
      &.invisible {
        display: none;
      }
    }
    &__image {
      max-width: 100%;
    }
    &__title {
      font-size: 20px;
      line-height: 24px;
    }
    &__subtitle {
      font-weight: 500;
      font-size: 13px;
      line-height: 150%;
      margin-bottom: 25px;
    }

    &__panel--w400 {
      max-width: 400px;
      width: 100%;
    }

    .form-group {
      margin-bottom: 15px;
      label {
        margin-bottom: 6px;
      }
    }

    &__footer {
      &--before-divider {
        margin-top: 30px;
        margin-bottom: 24px;
      }
      &--wide-btn {
        .btn {
          width: 100%;
          max-width: 260px;
        }
      }
      &--navigation-btn {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .btn {
          width: 100%;
          max-width: 260px;
          margin: 0;
          &:not(:first-child) {
            margin-bottom: 15px;
          }
        }
      }
    }

    .b-central__divider {
      margin-top: 24px;
      margin-bottom: 12px;
    }

    .onboarding &,
    .onboarding-password-forgot &,
    .onboarding-password & {
      .b-central__subtitle {
        margin-bottom: 50px;
      }
    }

    .onboarding-password-reset & {
      .b-central__title {
        margin-bottom: 50px;
      }
    }

    .onboarding & {
      .b-email-input-group .form-group {
        margin-bottom: 30px;
      }
      .b-central____message {
        margin-top: 50px;
        > a {
          display: block;
          margin-top: 30px;
        }
      }
    }

    .onboarding-sign-in & {
      .b-central__forgot-password-btn {
        margin-top: 30px;
      }
    }

    .onboarding-email-verification & {
      .b-central__message {
        font-size: 12px;
      }
    }

    .onboarding-password &,
    .onboarding-get-started & {
      .b-central__footer--navigation-btn {
        margin-top: 30px;
      }
    }

    .onboarding-password-reset & {
      .b-central__footer--navigation-btn {
        margin-top: 50px;
      }
    }

    &__corner {
      &--mobile {
        display: block;
      }
      &--desktop {
        display: none;
      }
    }
  }
}
