.b-verificode {
  font-size: 54px;
  line-height: 40px;

  &__group {
    display: inline-block;
  }

  &__divider {
    display: inline-block;
    width: 55px;
    text-align: center;
    color: $text-muted;
  }

  &__input {
    display: inline-block;
    width: 80px;
    height: 120px;
    text-align: center;
    color: $text-muted;
    padding: 0;

    border: 1px solid $input-border-color;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:not(:first-child) {
      border-left: none;
    }

    &:focus {
      outline: none;
    }
    &::placeholder {
      opacity: 0.1;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none; 
      margin: 0;
    }
  }
}

@include mobile {
  .b-verificode {
    font-size: 30px;
    line-height: 40px;

    &__divider {
      width: 20px;
    }

    &__input {
      width: 40px;
      height: 60px;
    }
  }
}
