
// Buttons

.btn {
  &:focus {
    box-shadow: none;
  }
  &.btn-secondary {
    color: $dark;
    background-color: white;
    border-color: $gray-300;
  }
  &.btn-info {
    color: white;
  }
  &.btn-almost-sm {
    border-radius: 18px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  &.btn-lg {
    border-radius: 23px;
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &:disabled,
  &.disabled {
    color: $gray-500;
    background-color: $gray-200;
    border-color: $gray-200;
  }

  &.btn-facebook {
    background-color: #3B5998;
    border-color: #3B5998;
    color: white;
  }

  &.btn-google {
    background-color: #DE5246;
    border-color: #DE5246;
    color: white;
  }

  .btn-icon-left {
    float: left;
    top: 3px;
    left: 3px;
    position: relative;
  }
}

// Form validation

.invalid-feedback {
  text-align: right;
}
.form-check {
  .invalid-feedback {
    text-align: left;
  }
}

.form-control {
  &.is-valid,
  &.is-invalid {
    &,
    &:focus {
      border-top-color: $input-border-color;
      border-left-color: $input-border-color;
      border-right-color: $input-border-color;
    }
    &:focus {
      box-shadow: none;
    }
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.m {
  &-b-15 {
    margin-bottom: 15px;
  }
  &-b-25 {
    margin-bottom: 25px;
  }
  &-b-24 {
    margin-bottom: 24px;
  }
  &-b-35 {
    margin-bottom: 35px;
  }
  &-b-50 {
    margin-bottom: 50px;
  }
}

.p {
  &-10 {
    padding: 10px;
  }
}
