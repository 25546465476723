
$purple: #8E8EEE;

$primary: #EF3278;
$success: #58cf98;
$danger: $primary;
$info: $purple;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default; // #E7E9F2
$gray-300: #DCDEE7;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #878DA9;
$gray-700: #495057 !default;
$gray-800: #373451;
$gray-900: #212529 !default;

$body-color: $gray-800;
$link-color: $purple;

$font-family-base: 'Mulish','Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
$font-size-base: 14px;

$nav-link-padding-y: 7px;
$nav-link-padding-x: 14px;
$custom-control-indicator-size: 14px;
$custom-control-gutter: 7px;
$alert-padding-x: 17.5px;

$btn-border-radius: 20px;
$btn-border-radius-sm: 17px;
$btn-border-radius-lg: 19px;
$btn-line-height: 22px;
$btn-padding-x: 20px;
$btn-padding-y: 8px;
$btn-focus-box-shadow: none;
$btn-font-weight: 600;
$btn-disabled-opacity: 1;

$enable-validation-icons: false;

$input-height: 40px;
$input-border-color: $gray-300;
$input-focus-border-color: $purple;
$input-focus-box-shadow: 0 0 4px $input-focus-border-color;

$form-feedback-margin-top: 1px;
$form-group-margin-bottom: 26px;

// MODAL

$modal-backdrop-color: #8885EC !default;
$modal-border-radius: 8px !default;

$color-modal-btn-primary: $primary !default;
$color-modal-btn-danger: $primary !default;
